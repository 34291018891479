import { AbstractControl, FormControl, FormControlOptions, FormGroup, ValidatorFn } from '@angular/forms';
import { VoucherCategory } from '@app/features/csv-upload/csv-upload.models';

export interface CsvTemplate<T = any> {
  type: CsvTemplateType;
  category: VoucherCategory;
  label?: string;
  description?: string;
  exampleData?: T;
  form: () => CsvTemplateFormGroup<T>;
}

export type CsvTemplateType = 'NEW_CARD' | 'RECARDING' | 'BULK_RECARDING';

export const CSV_TEMPLATE_TYPE: { [key in CsvTemplateType]: CsvTemplateType } = {
  NEW_CARD: 'NEW_CARD',
  RECARDING: 'RECARDING',
  BULK_RECARDING: 'BULK_RECARDING',
};

export class CsvTemplateFormControl<T = any> extends FormControl {
  label?: string;
  format?: Function;
  ignore = false;
  constructor(control: CsvTemplateFormControlData<T>) {
    super(control.value, control.validatorOrOpts);
    this.label = control.label;
    this.format = control.format;
    this.ignore = !!control.ignore;
  }

  setValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    if (this.format) {
      value = this.format(value);
    }
    super.setValue(value, options);
  }

  patchValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    if (this.format) {
      value = this.format(value);
    }
    super.patchValue(value, options);
  }
}

export class CsvTemplateFormGroup<T = any> extends FormGroup {
  condition?: (value: T) => boolean;
  constructor(
    controls: {
      [K in keyof T]: AbstractControl<any>;
    },
    validatorOrOpts: FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined = null,
    condition?: (value: T) => boolean
  ) {
    super(controls, validatorOrOpts);
    this.condition = condition;
  }
}

interface CsvTemplateFormControlData<T>
  extends Partial<{
    label: string;
    value: T;
    validatorOrOpts: FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined;
    // this column will be not send to Backend when true
    ignore: boolean;
    format: (value: T) => T;
  }> {}
