export const environment = {
  production: false,
  staging: true,
  domain: 'givve.io',
  apiUrl: 'https://search.staging.card.givve.io/api',
  adminApiUrlV1: 'https://www-staging.givve.io/api/admin',
  adminUrlV1: 'https://www-staging.givve.io/admin',
  complianceApiUrl: 'https://compliance.staging.card.givve.io',
  auditApiUrl: 'https://audit.staging.card.givve.io',
  b2bUrl: 'https://b2b-staging.givve.com',
  cardSuiteUrl: 'https://card-staging.givve.com',
  sentry: { dsn: null },
  version: require('../../package.json').version,
  changelogPolling: false,
};
